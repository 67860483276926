<template>
<icon-button name="save" class="ar-icon ar-icon__xs ar-icon--no-border" @click.native="upload"/>
</template>

<script>
  import IconButton from './icon-button'
  import UploaderPropsMixin from '../mixins/uploader-props'
  import { bus } from "../../../../main";
  import axios from "@/config/axios";
  export default {
    mixins: [UploaderPropsMixin],
    props: {
      record: { type: Object }
    },
    components: {
      IconButton
    },
    methods: {
      upload () {
        if (!this.record.url) {
          return
        }
       this.startUpload()

        const data = new FormData()
        data.append('audio', this.record.blob, `${this.filename}.mp3`)
        const headers = Object.assign(this.headers, {})
        headers['Content-Type'] = `multipart/form-data; boundary=${data._boundary}`
        axios.put(this.uploadUrl, data, { headers: headers }).then(resp => {
          bus.$emit('end-upload', { status: 'success', response: resp })
        }).catch(error => {
          bus.$emit('end-upload', { status: 'fail', response: error })
        })
      },
      startUpload() {
    bus.$emit('start-upload')
  },
  
    }
  }
</script>
<style lang="scss">
  @import '../scss/icons.scss';
</style>